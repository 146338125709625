import "./Home.css";
import React, { useEffect, useRef, useState } from "react";
import heroImg from "../../assets/images/home_hero.webp";
import customersImg from "../../assets/images/home_customers.webp";
import expertiseImg from "../../assets/images/home_expertise.webp";
import quoteImg from "../../assets/images/home_quote.webp";
import supportImg from "../../assets/images/home_support.webp";
import quoteIcon from "../../assets/icons/quote.svg";
import { Link } from "react-router-dom";
import { solutions, customers, stories, clients } from "../../Utils.js";
import Solution from "../../components/molecules/solution/Solution";
import TimelineContainer from "../../components/organisms/timelineContainer/TimelineContainer.jsx";
import ConvincingCard from "../../components/molecules/convincingCard/ConvincingCard.jsx";
import { Helmet } from "react-helmet";

function Home() {
  const [displayedCustomer, setDisplayedCustomer] = useState({
    index: 0,
    charIndex: 0,
    value: ""
  });
  const [isFading, setIsFading] = useState(true);
  const timelineRef = useRef(null);
  const [scrollInterval, setScrollingInterval] = useState(null);
  const [arrowsVisibility, setArrowsVisibility] = useState([false, true]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      updateDisplayedCustomer();
    }, 150);

    return () => clearTimeout(timeout);
  }, [displayedCustomer]);

  useEffect(() => {
    if (!timelineRef.current) return;

    timelineRef.current.addEventListener("scroll", handleArrowsVisibility);

    return () => {
      if (timelineRef.current)
        timelineRef.current.removeEventListener("scroll", handleArrowsVisibility);
    };
  }, []);

  function handleArrowsVisibility() {
    const { scrollTop, scrollHeight, clientHeight } = timelineRef.current;
    const topArrow = scrollTop > 0;
    const bottomArrow = scrollTop < scrollHeight - clientHeight;

    setArrowsVisibility([topArrow, bottomArrow]);
  }

  function updateDisplayedCustomer() {
    const customer = customers[displayedCustomer.index];

    if (customer.length === displayedCustomer.charIndex) {
      setIsFading(false);

      return setTimeout(() => {
        setIsFading(true);
        setDisplayedCustomer({
          index: displayedCustomer.index + 1 >= customers.length ? 0 : displayedCustomer.index + 1,
          charIndex: 0,
          value: ""
        });
      }, 1000);
    }

    setDisplayedCustomer({
      index: displayedCustomer.index,
      charIndex: displayedCustomer.charIndex + 1,
      value: `${displayedCustomer.value}${customer[displayedCustomer.charIndex]}`
    });
  }

  function startScroll(top) {
    setScrollingInterval(setInterval(() => scrollTimeline(top), 10));
  }

  function stopScroll() {
    clearTimeout(scrollInterval);
    setScrollingInterval(null);
  }

  function scrollTimeline(top) {
    timelineRef.current.scrollBy({ left: 0, top: top ? -5 : 5 });
  }

  return (
    <div className="home-container">
      <Helmet>
        <title>Boostez la performance commerciale de votre entreprise en Santé</title>
        <meta
          name="description"
          content="Grâce à une connaissance des enjeux l’écosystème de Santé et un accompagnement personnalisé, Vitale Performance Santé améliore la performance commerciale"
        />
        <link rel="canonical" href="https://www.vitaleperformancesante.fr" />
        <meta
          property="og:title"
          content="Boostez la performance commerciale de votre entreprise en Santé"
        />
        <meta
          property="og:description"
          content="Grâce à une connaissance des enjeux l’écosystème de Santé et un accompagnement personnalisé, Vitale Performance Santé améliore la performance commerciale"
        />
      </Helmet>
      <section className="page-container home-hero">
        <div>
          <h1>
            Boostez la performance
            <br /> et la santé de votre
            <br /> entreprise
          </h1>
          <p>
            Vitale Performance Santé accompagne les entreprises et structures de la santé (start-up,
            scale-up, medtech…) pour améliorer leur performance commerciale et opérationnelle avec
            des outils sur mesure basés sur l’analyse de leurs besoins stratégiques.
          </p>
          <Link className="gradient-button" to="/contact">
            Demander une démo
          </Link>
        </div>
        <div className="image-placeholder" />
        <img src={heroImg} alt="Vitale Performance Santé boost entreprise" />
      </section>
      <section className="home-support">
        <div className="page-container">
          <img src={supportImg} alt="Vitale Performance Santé support" />
          <h2>Un accompagnement personnalisé pour chaque besoin</h2>
          <p>
            Grâce à une expertise éprouvée des enjeux commerciaux dans le domaine Médical et de la
            Santé, nous proposons des solutions adaptées pour chaque marché afin de permettre à nos
            partenaires d’accélérer leur croissance et de pérenniser leur business model.
          </p>
        </div>
      </section>
      <section className="home-convincing page-container">
        <div className="convincing-title">
          <h2>Pourquoi choisir Vitale Performance Santé ?</h2>
          <p>
            Vous êtes une entreprise issue du monde de la Santé : start-up, scale-up, medtech, fonds
            d’investissement, accélérateurs, PME dans le digital santé, groupes pharmaceutiques,
            cabinet de recrutement dans la santé : voilà pourquoi faire appel à Vitale Performance
            Santé.
          </p>
        </div>
        <ConvincingCard
          title="Une expertise de l'écosystème de Santé"
          bottomContent="Nous connaissons parfaitement l’écosystème de Santé pour avoir travaillé pendant 20
            ans à la fois pour des éditeurs de logiciels médicaux, pour des professionnels et
            structures de santé, pour des institutionnels, medtech, et pour des entreprises B to B
            issues de l’industrie pharmaceutique à des postes de direction commerciale (membre de
            comités de Direction)."
          content="Se faire accompagner par un expert du monde médical et de la Tech Santé."
        />
        <ConvincingCard
          title="Une maitrise des enjeux commerciaux stratégiques des Start up et Scale up"
          bottomContent="Nous connaissons parfaitement l’écosystème de Santé pour avoir travaillé pendant 20
            ans à la fois pour des éditeurs de logiciels médicaux, pour des professionnels et
            structures de santé, pour des institutionnels, medtech, et pour des entreprises B to B
            issues de l’industrie pharmaceutique à des postes de direction commerciale (membre de
            comités de Direction)."
          content="S’appuyer sur une entreprise qui maitrise les attentes commerciales du Marché."
        />
        <div className="convincing-placeholder" />
        <ConvincingCard
          title="La performance commerciale vecteur de croissance et créatrice de valeur"
          content="Créer de la valeur en boostant la performance de votre structure."
          bottomContent="Vitale Performance Santé a pour obsession la performance commerciale, qui a toujours été
          le 'driver' de la croissance pour une entreprise. Cette performance commerciale et
          opérationnelle, si elle est bien gérée et intégrée dans la culture d'entreprise, permet de
          créer de la valeur et de pérenniser le business model d'une société."
        />
        <ConvincingCard
          title="Des solutions adaptées et mesurables avec un accompagnement personnalisé"
          content="Saisir les opportunités du marché grâce à un accompagnement sur mesure et à long terme."
          bottomContent="Vitale Performance Santé a développé des solutions agiles qui répondent à tous vos besoins
          commerciaux et opérationnels : missions de conseil pour les fonds d’investissement et les
          accélérateurs, audit commercial et stratégique, génération de leads, recrutement, coaching
          management d’équipes commerciales, sous-traitance de direction commerciale. Nous suivons
          nos clients avant, pendant et surtout après nos prestations, car nous savons que le succès
          de la performance réside dans l’accompagnement et l’effort constant."
        />
      </section>
      <section className="home-solutions">
        <div className="page-container">
          <h2>Nos solutions</h2>
          <p>
            Vitale Performance Santé a les solutions pour répondre à<br /> ces enjeux&nbsp;:
          </p>
          <div className="home-solutions-container">
            {solutions.map((solution) => (
              <Solution key={solution.id} title={solution.title} id={solution.id} />
            ))}
          </div>
        </div>
      </section>
      <section className="home-customers page-container">
        <img src={customersImg} alt="Vitale Performance Santé clients" />
        <div className="home-customers-content">
          <p className="customers-subtitle">Nous travaillons avec les</p>
          <h2 className={isFading ? "fade-in" : "fade-out"}>
            {displayedCustomer.value}
            <span className="blinking-cursor" />
          </h2>
          <p className="customers-description">
            Nous accompagnons de nombreux acteurs du monde de la santé grâce à notre gamme de
            solutions et services basés sur la performance commerciale et opérationnelle : start-up,
            scale-up, medtech, fonds d’investissement, accélérateurs, PME dans le digital santé,
            groupes pharmaceutiques, cabinets de recrutements dans la santé.
          </p>
        </div>
      </section>
      <section className="home-method">
        <div className="home-method-header">
          <div className="page-container">
            <div className="timeline-arrows">
              <button
                className={`timeline-arrow ${arrowsVisibility[0] ? "visible" : ""}`}
                onMouseDown={() => (arrowsVisibility[0] ? startScroll(true) : null)}
                onMouseUp={stopScroll}
              />
              <button
                className={`timeline-arrow ${arrowsVisibility[1] ? "visible" : ""}`}
                onMouseDown={() => (arrowsVisibility[1] ? startScroll() : null)}
                onMouseUp={stopScroll}
              />
            </div>
            <h2>
              La méthode <br /> Vitale Performance Santé
            </h2>
            <p>Une approche structurée, pour des résultats concrets.</p>
          </div>
        </div>
        <div className="home-method-timeline-container" ref={timelineRef}>
          <div className="home-method-timeline">
            <div className="page-container">
              <TimelineContainer />
            </div>
          </div>
        </div>
      </section>
      <section className="home-expertise page-container">
        <div>
          <h2>Une expertise acquise dans le temps</h2>
          <ul>
            <li>
              20 ans d’expérience en tant que directeur commercial et membre des comités de
              direction
            </li>
            <li>Expertise des enjeux opérationnels des acteurs de l’écosystème de santé</li>
            <li>Board member de plusieurs start-up en santé</li>
          </ul>
        </div>
        <img src={expertiseImg} alt="Vitale Performance Santé expertise" />
      </section>
      <section className="home-values">
        <div className="page-container">
          <h2>Les valeurs qui guident notre travail</h2>
          <div className="home-values-container">
            <div className="value-card">Implication et réactivité</div>
            <div className="value-card">Accompagnement personnalisé</div>
            <div className="value-card">Transparence</div>
            <div className="value-card">Priorité absolue donnée au client dans le temps</div>
            <div className="value-card">Ethique</div>
            <div className="value-card">Garantie de ROI</div>
          </div>
        </div>
      </section>
      <section className="home-quote">
        <div className="page-container">
          <img src={quoteImg} alt="Vitale Performance Santé quote" />
          <div className="quote-container">
            <img src={quoteIcon} alt="quote" />
            <p>
              Je suis convaincu que la moitié qui sépare les entrepreneurs qui réussissent de ceux
              qui échouent est purement la persévérance.
            </p>
            <div className="quote-author">Steve Jobs</div>
          </div>
        </div>
      </section>
      <section className="home-stories">
        <div className="page-container">
          <h2>Ils nous ont fait confiance</h2>
          <div className="clients-container">
            {clients.map((client) => (
              <img src={require(`../../assets/logos/stories/${client.logo}`)} alt={client.title} />
            ))}
          </div>
          <div className="stories-container">
            {stories.map((story) => (
              <div className="story-container" key={story.id}>
                <img src={quoteIcon} alt="quote" />
                <img
                  className="story-logo"
                  alt={story.title}
                  src={require(`../../assets/logos/stories/${story.logo}`)}
                />
                <h3>{story.title}</h3>
                <p>{story.content}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
