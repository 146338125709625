export const defaultValidation = { required: "Ce champ est obligatoire" };

export const phoneValidation = {
  required: "Ce champ est obligatoire",
  pattern: {
    value:
      /^[+]?[0-9]{2}[-\s.]?[0-9]{0,2}[-\s.]?[0-9]{2}[-\s.]?[0-9]{2}[-\s.]?[0-9]{2}[-\s.]?[0-9]{2}$/,
    message: "Numéro de téléphone invalide"
  }
};

export const emailValidation = {
  required: "Ce champ est obligatoire",
  pattern: {
    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    message: "Adresse mail invalide"
  }
};

export const selectValidation = {
  validate: (value) => value !== "_null" || "Ce champ est obligatoire"
};

export const customers = [
  "Start-ups et scale-ups Santé",
  "Medtech",
  "PME dans le Digital Santé",
  "Industrie Pharmaceutique",
  "Etablissements de Santé",
  "Fonds d’investissement",
  "Accélérateurs et Incubateurs",
  "Cabinets de Recrutement dans la Santé"
];

export const solutions = [
  {
    id: 0,
    title: "Booster Lead avec Accompagnement OP",
    target: "Start up, Scale up, Medtech, Pme, Grands Groupes",
    challenge:
      "Vous avez besoin de générer des leads et de booster votre chiffre d'affaires, et vous souhaitez un accompagnement opérationnel sur le terrain.",
    solution:
      "L’OFFRE BOOSTER LEAD avec accompagnement OP vous permet de booster les performances commerciales de votre entreprise avec une méthode éprouvée depuis 20 ans auprès de start-up, scale-up et PME.",
    process:
      "Nous trouvons ensemble les canaux de vente et les stratégies commerciales les mieux adaptés pour générer des leads rapidement et obtenir des résultats concrets à court terme. Nous accompagnons également votre équipe commerciale sur le terrain pour garantir une efficacité maximale.\nBriefe, audit, recommandation avec plan d’actions incluant des indicateurs de performance, puis le suivi de la performance.",
    tarif: "Sur-mesure"
  },
  {
    id: 1,
    title: "Booster Lead sans Accompagnement",
    target: "Start up, Scale up, Medtech, Pme, Grands Groupes",
    challenge:
      "Vous avez besoin de générer des leads et de booster votre chiffre d'affaires, et vous souhaitez un accompagnement sous forme de conseils opérationnels",
    solution:
      "L’OFFRE BOOSTER LEAD Sans Accompagnement OP vous permet de booster les performances commerciales de votre entreprise avec une méthode éprouvée depuis 20 ans auprès de start-up, scale-up et PME.",
    process:
      "Nous trouvons ensemble les canaux de vente et les stratégies commerciales les mieux adaptées à votre business pour générer des leads rapidement et obtenir des résultats concrets à court terme.\nBriefe, audit, recommandation avec plan d’actions incluant des indicateurs de performance, puis le suivi de la performance.",
    tarif: "Sur-mesure"
  },
  {
    id: 2,
    title: "Booster Sales/RH",
    target: "Start up, Scale up, Medtech, Pme, via Cabinet de Recrutement",
    challenge:
      "Vous êtes une Start up, une Medtech, une Pme… vous voulez vous assurer du bon recrutement de votre équipe commerciale et garantir la bonne intégration et la performance commerciale de cette équipe commerciale sur le long terme.",
    solution:
      "L’offre Booster/Sales RH garantit le bon recrutement de vos équipes commerciales grâce à un audit commercial et RH, une mission de recrutement personnalisée avec des recommandations opérationnelles, un onboarding des personnes recrutées, et un suivi des performances commerciales.",
    tarif: "sur mesure en fonction du briefe client"
  },
  {
    id: 3,
    title: "Mission Consulting Ponctuel",
    target: "Start up, Scale up, Medtech, Pme, incubateurs, Accélérateurs, fonds d’investissement.",
    challenge:
      "Vous souhaitez être conseillé de manière ponctuelle sur des sujets tactiques, commerciaux et opérationnels pour booster les ventes et accompagner la croissance de votre structure.",
    solution:
      "Nous effectuons chez vous des missions d’audit et de recommandations opérationnelles pour répondre à vos besoins.",
    process:
      "Briefe client, audit, définition des objectifs, définition et mise en place d’un plan d’actions avec indicateurs de performance pour répondre à vos besoins, analyse des résultats, mesures correctives, suivi longitudinal de la performance.",
    tarif: "800 HT € / jour"
  },
  {
    id: 4,
    title: "Mission Consulting Long Terme",
    target: "Start up, Scale up, Medtech, Pme, incubateurs, Accélérateurs, fonds d’investissement.",
    challenge:
      "Vous souhaitez être conseillé sur le moyen ou long terme sur des sujets stratégiques, structurels, commerciaux et opérationnels, avec des recommandations pour accélérer les performances commerciales de votre structure.",
    solution:
      "Nous effectuons chez vous des missions d’audit et de recommandations opérationnelles pour répondre à vos besoins en accompagnant vos équipes commerciales, produits et supports. Nous définissons ensemble des indicateurs de performance adaptés à vos objectifs.",
    process:
      "Briefe client, audit, définition des objectifs de la mission, définition et mise en place d’un plan d’actions pour répondre à vos besoins, analyse des résultats, mesures correctives, suivi longitudinal de la performance.",
    tarif: "Sur mesure en fonction du briefe client"
  },
  {
    id: 5,
    title: "Sous traitance Direction Commerciale",
    target: "Fond d’investissement ou Start up, Scale up, Pme",
    challenge:
      "Vous souhaitez sous-traiter de manière ponctuelle (ou permanente) votre fonction de direction commerciale, par manque de disponibilité ou de connaissance opérationnelle commerciale.",
    solution:
      "Nous mettons à votre disposition une ressource très expérimentée dans la création et le management des équipes commerciales pour structurer votre service commercial et accompagner votre croissance.",
    process:
      "Nous définissons ensemble un briefe avec les objectifs attendus en fonction de votre problématique, nous élaborons un plan d’action avec des indicateurs de performance, nous réalisons une analyse des performances, nous mettons en place des actions correctives, et établissons un bilan.",
    tarif: "Sur mesure en fonction du briefe client"
  },
  {
    id: 6,
    title: "Synergie VM",
    target: "Industrie pharmaceutique",
    challenge:
      "Vous êtes un laboratoire pharmaceutique ou une entreprise issue du milieu pharmaceutique et souhaitez renforcer vos liens avec vos prescripteurs tout en établissant une relation de confiance pour valoriser le discours médical de vos visiteurs médicaux. Vous avez également la volonté de changer le regard des professionnels de santé vis-à-vis de l’industrie pharmaceutique ? Nous avons la solution collaborative qu'il vous faut.",
    process:
      "L’application Moofize renforce la mise en relation entre les visiteurs médicaux et les professionnels de santé en créant un canal de communication spécifique et sécurisé pour la prise de rendez-vous et la diffusion d’informations médicales stratégiques à haute valeur ajoutée."
  },
  {
    id: 8,
    title: "Target Start up fond d'investissement",
    target: "Fonds d’investissement",
    challenge:
      "Vous êtes un fonds d’investissement et souhaitez détecter de nouvelles cibles de start-up santé à fort potentiel avec des positionnements très spécifiques. Vitale Santé Performance peut vous aider à trouver ces « pépites » grâce à son réseau et à sa très bonne connaissance de l’écosystème de la santé.",
    solution:
      "Vitale Performance Santé définit avec le client un cahier des charges, une lettre de mission et un périmètre d’actions. Nous identifions des cibles puis réalisons un audit commercial et financier de ces cibles. Vitale Performance Santé propose un rapport au fonds d’investissement avec des recommandations. Le fonds d’investissement valide une ou plusieurs cibles puis prend le relais. À la signature du deal entre le fonds d’investissement et la start-up, Vitale Performance Santé perçoit une commission sur la transaction en plus de la mission de conseil qui est facturée.",
    process:
      "Nous définissons ensemble un briefe avec les objectifs attendus en fonction de votre problématique, élaborons un plan d’actions avec des indicateurs de performance, réalisons une analyse des performances, mettons en place des actions correctives et établissons un bilan.",
    tarif: "Sur mesure"
  }
];

export const clients = [
  {
    id: 1,
    title: "Moofize",
    logo: "moofize.svg",
  },
  {
    id: 2,
    title: "Femmnov",
    logo: "fem_nove.svg",
  },
  {
    id: 4,
    title: "Telos Santé",
    logo: "telos.svg",
  },
  {
    id: 3,
    title: "JicalmBox",
    logo: "jicalmbox.svg",
  },,
  {
    id: 5,
    title: "Continuum Plus",
    logo: "continuum.svg",
  },

]

export const stories = [
  {
    id: 1,
    title: "Moofize",
    logo: "moofize.svg",
    content:
      "Mon cher Guillaume, un simple petit message pour te remercier de ton implication et de ce que tu fais et dis sur Moofize !\n\nEt aussi c’est un vrai bonheur d’échanger et collaborer avec toi ! Tu es tellement passionné c’est un vrai plaisir !"
  },
  {
    id: 2,
    title: "Femmnov",
    logo: "fem_nove.svg",
    content:
      "Comme tu l'as bien résumé, nous avons cette même vision du business et de l'approche \"Clients/Business First\".\n\nAu plaisir de poursuivre nos échanges et nos ateliers ensemble !"
  },
  {
    id: 4,
    title: "Telos Santé",
    logo: "telos.svg",
    content:
      "Nous sommes très heureux de compte Guillaume Roberte parmis les meilleurs Experts de l'écosystème santé TELOS, en incarnant l'excellence et l'innovation.\n\nEn tant que Président fondateur de Vitale Performance Santé, il met à disposition son expertise en Sales & Organisation commerciel et opérationnelle dans ce secteur complexe que représent la Santé.\n\nProfessionnel inspirant, Guillaume Robertet est une référence incontournable pour quiconque chercher à transformer les pratiques et à améliorer les performances avec une dynamique de progrès."
  },
  {
    id: 3,
    title: "JicalmBox",
    logo: "jicalmbox.svg",
    content:
      "Un immense merci, Guillaume Robertet, ton accompagnement est bien plus qu’un simple coaching\xa0: c’est une véritable transformation.\n\nGrâce à ton expertise et à ta capacité à éclairer chaque étape avec clarté, j’ai pu non seulement structurer mes idées, mais aussi trouver des solutions adaptées pour faire grandir Jicalmbox."
  },
];
